export const VEHICLE_STATUS_DRAFT = "DRAFT";
export const VEHICLE_STATUS_COMPLETED = "COMPLETED";
export const VEHICLE_AUCTION_STATUS_ONGOING = "ONGOING";

export const VEHICLE_STATUS_LIST = [
  VEHICLE_STATUS_DRAFT,
  VEHICLE_STATUS_COMPLETED,
];

export const VEHICLE_CAR_TYPE_CONVERTIBLE = "CONVERTIBLE";
export const VEHICLE_CAR_TYPE_COUPE = "COUPE";
export const VEHICLE_CAR_TYPE_HATCHBACK = "HATCHBACK";
export const VEHICLE_CAR_TYPE_SEDAN = "SEDAN";
export const VEHICLE_CAR_TYPE_SUV = "SUV";
export const VEHICLE_CAR_TYPE_TRUCK = "TRUCK";
export const VEHICLE_CAR_TYPE_VAN = "VAN";
export const VEHICLE_CAR_TYPE_STATION_WAGON = "STATION_WAGON";
export const VEHICLE_CAR_TYPE_COMMERCIAL = "COMMERCIAL";

export const VEHICLE_CAR_TYPES = [
  VEHICLE_CAR_TYPE_CONVERTIBLE,
  VEHICLE_CAR_TYPE_COUPE,
  VEHICLE_CAR_TYPE_HATCHBACK,
  VEHICLE_CAR_TYPE_SEDAN,
  VEHICLE_CAR_TYPE_SUV,
  VEHICLE_CAR_TYPE_TRUCK,
  VEHICLE_CAR_TYPE_VAN,
  VEHICLE_CAR_TYPE_STATION_WAGON,
  VEHICLE_CAR_TYPE_COMMERCIAL,
];

// drivetrans
export const VEHICLE_DRIVETRAIN_LIST = ["4x4", "4WD", "AWD", "FWD", "RWD"];

// colors
export const VEHICLE_COLORS = {
  BLACK: "#000000",
  WHITE: "#FFFFFF",
  GRAY: "#B8B8B8",
  BLUE: "#2B2BB9",
  GREEN: "#008000",
  RED: "#FF0000",
  YELLOW: "#FFFF00",
};

// conditions
export const VEHICLE_CONDITION_BAD = "BAD";
export const VEHICLE_CONDITION_AVERAGE = "AVERAGE";
export const VEHICLE_CONDITION_GOOD = "GOOD";
export const VEHICLE_CONDITION_NEW = "NEW";

export const VEHICLE_CONDITION_LIST = [
  VEHICLE_CONDITION_BAD,
  VEHICLE_CONDITION_AVERAGE,
  VEHICLE_CONDITION_GOOD,
  VEHICLE_CONDITION_NEW,
];
